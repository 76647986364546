import React from "react";
import VideoCompany from "../static/mpl.mp4";
import VideoLactoferrin from "../static/lactoferrin.mp4";

export default function Video({ videoCode }) {
    let videoFile;
    if(videoCode === "china") {
        videoFile = VideoCompany
    } else {
        videoFile = VideoLactoferrin
    }
  return (
    <video controls>
      <source src={videoFile} type="video/mp4" />
    </video>
  );
}
